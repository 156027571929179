.header{
    display: flex;
    justify-content:space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}

.header_menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
        
}
.header_menu>li:hover{
    cursor: pointer;
    color: var(--orange);
}